/**
 * api接口统一管理
 */
import axios from '../utils/http';
import request from '../utils/request';
import base from '../utils/base';
import qs from 'qs';
const api = {
  /**
   * 1.wx.config
   */
  wxOpenWxJsApi(url) {
    // let allurl = encodeURIComponent(url)
    return axios.get(`${base.sq}/charging.do?method=wxOpenWxJsApi&allurl=${url}`);
  },
  weChatLogin(code) {
    return axios.get(`${base.sq}/login.do?method=weChatLogin&code=${code}`);
  },
  /**
   * 1.获取用户微信信息
   */
  accountInfo() {
    return axios.get(`${base.sq}/me.do?method=accountInfo`);
  },
  //获取tips
  getFeeTips(params) {
    return axios.get(`${base.sq}/price.do?method=getFeeTips`, {
      params
    })
  },

  /**
   * 2.获取广告
   *
   * 展示页面
   * 1001	扫码成功
   * 1002	支付成功
   * 1003	充值记录
   * 1004	问题反馈
   */
  showAdvert(page) {
    return axios.get(`${base.sq}/me.do?method=showAdvert&showPage=${page}`);
  },
  /**
   * 3.获取优惠数据
   */
  promotionInfo(search_totalAmount) {
    return axios.get(`${base.sq}/me.do?method=showPromotionInfo&search_totalAmount=${search_totalAmount}`);
  },
  /**
   * 4.获取充电桩数据
   */
  getChargingPileData(cid) {
    return axios.get(`${base.sq}/charging.do?method=getChargingPileData&key=${cid}`);
  },
  /**
   *5.获取反馈意见列表
   */
  getCommon() {
    return axios.get(`${base.sq}/me.do?method=getCommonCode&type=4`)
  },
  showOrderDetail(id){
    return axios.get(`${base.sq}/order.do?method=getOrderDetail&order_orderNumber=${id}`)
  },
  /**
   * 6.获取订单列表
   * 订单状态 5001 正在使用
   * 5003 已完成订单
   */
  orderList(search_orderStatus ,search_page) {
    return axios.post(`${base.sq}/order.do?method=orderList&search_orderStatus=${search_orderStatus}&search_page=${search_page}`)
  },
  /**
   * 取消订单
   */
  userCancelOrder(ordernumber){
    return axios.post(`${base.sq}/order.do?method=userCancelOrder&ordernumber=${ordernumber}`)
  },
  /**
   * 7.获取用户卡列表
   * search_cardNo 电卡编号(非必填)
   *search_state 状态 0 正常 1 已挂失 (非必填)
   *
   */
  queryUserCard(cardNo, search_state) {
    return axios.post(`${base.sq}/me.do?method=queryUserCard`, qs.stringify({
      'search_cardNo': cardNo,
      'search_state': search_state,

    }))

  },
  /**
   * 8.绑定电卡
   *
   *
   */
  bindCard(cardNo) {
    return axios.post(`${base.sq}/me.do?method=bindCard`, qs.stringify({
      'cardNo': cardNo,

    }))
  },
  /**
   * 9.查询用户余额
   */
  queryAccountBalance() {
    return axios.get(`${base.sq}/me.do?method=queryAccountBalance`)
  },

  /**
   * 10.提交反馈意见
   */
  saveFeedBack(param) {
    return axios.post(`${base.sq}/me.do?method=saveFeedBack`, qs.stringify(param))
  },
  /**
   * 11.展示按小时收费的充电桩价格
   * chargingPileNo 充电桩编号
   *
   */

  queryChargingPilePrice(chargingPileNo, chargeType) {
    return axios.post(`${base.sq}/price.do?method=queryChargingPilePrice`, qs.stringify({
      'chargingPileNo': chargingPileNo,
      'chargeType': chargeType

    }))
  },
  /**
   * 开始充电
   *
   */
  saveOrder(order_order_type, order_port_id, order_hour) {
    return axios.get(`${base.sq}/order.do?method=saveOrder&order_order_type=${order_order_type}&order_port_id=${order_port_id}&order_hour=${order_hour}`

    )
  },
  /**
   * 12获取短信验证码
   *
   */
  sendSMS(mobile) {
    return axios.post(`${base.sq}/me.do?method=sendSMS`, qs.stringify({
      'mobile': mobile,

    }))
  },
  /**
   * 12绑定手机号
   *
   */
  bindMobile(mobile, code) {
    return axios.get(`${base.sq}/me.do?method=bindMobile&mobile=${mobile}&code=${code}`)
  },
  /**
   * 充值
   *
   */
  recharge(charge_amount, charge_cardNo) {
    return axios.post(`${base.sq}/pay.do?method=recharge`, qs.stringify({
      'charge_amount': charge_amount,
      'charge_cardNo': charge_cardNo

    }))
  },
  //充值记录
  rechargeRecord(page) {
    return axios.get(`${base.sq}/pay.do?method=rechargeRecord&search_page=${page}`)
  },
  //统计充电桩销售数据
  salesDetailByParams(params) {
    return axios.get(`/management/getSalesDetailByParams.do?parkid=${params.parkid}&day=${params.day}&startTime=${params.startTime}&endTime=${params.endTime}`)
  },

  //运营端
  //登录
  Login(userName, password) {
    return request.post(`/wx/login`, qs.stringify({
      'userName': userName,
      'password': password

    }))
  },
  //登出
  loginOut() {
    return request.get(`/wx/loginOut`)
  },
  //修改密码
  changePwd(newPassword, password) {
    return request.post(`/wx/changePwd`, qs.stringify({
      'newPassword': newPassword,
      'password': password

    }))
  },

  //省市三级联动
  getProvinceList() {
    return request.get(`/city/getProvinceList`)
  },
  getAreaList() {
    return request.get(`/city/getAllAddress`)
  },
  getCityList(pid) {
    return request.get(`/city/getCityList?pid=${pid}`)
  },
  getPoltList(regionCode) {
    return request.get(`/city/getPoltList?regionCode=${regionCode}`)
  },
  //公共代码
  getCodesByParent(parentCode) {
    return request.get(`/getCodesByParent?parentCode=${parentCode}`)
  },
  //充电桩列表
  getChargingPileList(params) {
    return request.get(`/wx/chargingpile/getChargingPileList`, {
      params
    });
  },
  //添加充电桩POST
  addPile(param) {
    return request.post(`/wx/chargingpile/addPile`, qs.stringify(param))
  },
  editPile(param) {
    return request.post(`/wx/chargingpile/editPile`, qs.stringify(param))
  },
//充电桩端口管理列表
getPortList(params) {
  return request.get(`/chargingport/getPortList`, {
    params
  });
},

//充电桩规则设置GET
setPileRule(params) {
  return request.get(`/wx/chargingpile/setPileRule`, {
    params
  });
},
//添加端口
addPort(param) {
  return request.post(`/chargingport/addPort`, qs.stringify(param))
},
//编辑端口
editPort(param) {
  return request.post(`/chargingport/editPort`, qs.stringify(param))
},
//删除端口DELETE
delPort(id) {
  return request.delete(`/chargingport/delPort?id=${id}`)
},

  //Grain Rain 2020年3月11日21:41:41 销售数据--统计充电桩销售数据
  // salesDetailByParams(params) {
  //   return request.get(`/management/getSalesDetailByParams`,{params})
  // },
  //今日收益 ,返回金额和订单总数
  getDayProfit(params) {
    return request.get(`/management/getDayProfit`,{params})
  },
  //本月收益
  getMonProfit() {
    return request.get(`/management/getMonProfit`)
  },
  //经营管理首页数据
  getOperateData() {
    return request.get(`/management/getOperateData`)
  },
 //经营管理地址管理
 addressStatistics(params) {
  return request.get(`/management/addressStatistics`, {
    params
  });
},
  //用户反馈列表
  getFeedBackList(params) {
    return request.get(`/feedback/getFeedBackList`, {
      params
    })
  },
  //订单列表
  getOrderList(params) {
    return request.get(`/chargingorder/getOrderList`, {
      params
    })
  },
  refundOrder(data) {
    return request({
      url: `/sysOrder/refundOrder`,
      method: 'get',
      params: data

    })
  },
  changePort(data) {
    return request({
      url:  `/sysOrder/portChange`,
      method: 'get',
      params: data

    })
  },
  //单个用户反馈POST /feedback/doFeedBack
  getFeedBack(id) {
    return request.post(`/feedback/getFeedBack`, qs.stringify({
      'id': id,


    }))
  },
  //处理用户反馈
  doFeedBack(id, comment, state) {
    return request.post(`/feedback/doFeedBack`, qs.stringify({
      'id': id,
      'comment': comment,
      'state': state

    }))
  },
  //获取卡列表
  getCardList(params) {
    return request.get(`/card/getCardList`, {
      params
    })
  },
  //挂失卡
  lossCard(cardid) {
    return request.get(`/card/lossCard?cardNo=${cardid}`)
  },
  //新增卡
  addCard(param) {
    return request.post(`/card/addCard`, qs.stringify(param))
  },
  //编辑卡
  editCard(param) {
    return request.post(`/card/editCard`, qs.stringify(param))
  },
  //卡使用列表
  getUseRecods(params) {
    return request.get(`/card/getUseRecods`,{params})
  },
  //卡充值列表
  getRechargeRecods(params) {
    return request.get(`/card/getRechargeRecods`,{params})
  },
  //用户管理列表
  getUserList(params) {
    return request.get(`/user/getUserList`, {
      params
    })
  },
  //获取用户余额
  getMyData() {
    return request.get(`/my/getMyData`)
  },
  //获取用户提现记录列表
  getCashRecord(params) {
    return request.get(`/my/getCashRecord`, {
      params
    })
  },
  //规则列表
  getRuleList(params) {
    return request.get(`/rule/getRuleList`, {
      params
    })
  },
  //添加规则
  addRule(param) {
    return request.post(`/rule/addRule`, qs.stringify(param))
  },
  //复制规则
  copyRule(params) {
    return request.get(`/rule/copyRule`,{
      params
    })
  },
  //获取规则价格列表GET
  getRulePriceList(id) {
    return request.get(`/rule/getRulePriceList?id=${id}`)
  },
  //获取规则GET
  getRule(id) {
    return request.get(`/rule/getRule?id=${id}`)
  },
  //添加规则价格
  addCustomPrice(param) {
    return request.post(`/rule/addCustomPrice`, qs.stringify(param))
  },
  //修改规则价格POST POST
  editCustomPrice(param) {
    return request.post(`/rule/editCustomPrice`, qs.stringify(param))
  },
  //删除规则价格DELETE DELETE
  delCustomPrice(id) {
    return request.delete(`/rule/delCustomPrice?id=${id}`)
  },
  //绑定提现wxGET
  bindWx(code) {
    return request.get(`/my/bindWx?code=${code}`)
  },
  //提现GET /my/cashWithdrawal
  cashWithdrawal(code,money) {
    return request.get(`/my/cashWithdrawal?code=${code}&amount=${money}`)
  },
  //发送验证码GET
  sendMsg(phone) {
    return request.get(`/my/sendMsg?mobile=${phone}`)
  },
  //获取用户信息
  userInfo() {
    return request.get(`/registerUser/userInfo`)
  },
  switchPort(param) {
    return request.post(`/port/switchPort`, qs.stringify(param))
  },
  //获取设备
  getDevice(id) {
    return request.get(`/wx/chargingpile/getPileById?id=${id}`)
  },
}
export default api;
