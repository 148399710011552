import Vue from "vue";
import Router from "vue-router";
import store from "store";
import Store from "@/store/store";

Vue.use(Router);

export const constantRoutes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: () => import('@/views/consumers/clients/sweepindex'),
    // },
    // { path: '/', redirect: '/sweep' },
    /*
     **授权页
     **
     */
    {
        path: "/author",
        component: () => import("@/views/author"),
    },
    /*
     **登录
     **
     */
    {
        path: "/login",
        name: "特艾充",
        component: () => import("@/views/login/index"),
    },
    /*
     **404
     **
     */
    {
        path: "/404",
        component: () => import("@/views/error-page/404"),
    },
    /*
     **401
     **
     */
    {
        path: "/401",
        component: () => import("@/views/error-page/401"),
    },
    /*
     ** 运营端
     **
     */
    {
        path: "/",
        name: "Admin",
        redirect: "/admin/equipment",
        component: () => import("@/views/operations/admin"),
        children: [
            /*
             ** 主页
             **
             */
            {
                path: "/admin/index",
                name: "经营管理",
                component: () => import("@/views/operations/mine/index"),
            },
            /*
             ** 经营管理
             **
             */
            {
                path: "/admin/equipment",
                name: "首页",
                // component: () => import('@/views/operations/equipment/equipment'),
                component: () => import("@/views/operations/mine/dataView"),
            },
            /*
             **账单明细
             **
             */
            {
                path: "/admin/bil",
                name: "Bil",
                component: () => import("@/views/operations/equipment/bil"),
            },
            /*
             **地址统计
             **
             */
            {
                path: "/admin/address",
                name: "Address",
                component: () => import("@/views/operations/equipment/address"),
            },
            /*
             **时间统计
             **
             */
            {
                path: "/admin/time",
                name: "Time",
                component: () => import("@/views/operations/equipment/time"),
            },

            /*
             **订单管理
             **
             */
            {
                path: "/admin/order",
                name: "订单列表",
                component: () => import("@/views/operations/order/order"),
            },
            {
                path: "/admin/orderDetail",
                name: "订单详情",
                component: () => import("@/views/operations/order/orderDetail"),
            },
            {
                path: "/admin/orderBackMoney",
                name: "退款",
                component: () => import("@/views/operations/order/orderBackMoney"),
            },
            {
                path: "/admin/portChange",
                name: "订单编辑",
                component: () => import("@/views/operations/order/portChange"),
            },
            /*
             **订单统计管理
             **
             */
            {
                path: "/admin/ordersales",
                name: "查看收益",
                component: () => import("@/views/operations/order/ordersales"),
            },

            /*
             **卡管理
             **
             */
            {
                path: "/admin/card",
                name: "卡管理",
                component: () => import("@/views/operations/card/card"),
            },
            /*
             **卡充值记录
             **
             */
            {
                path: "/admin/cardinvest/:id",
                name: "卡充值记录",
                component: () => import("@/views/operations/card/cardinvest"),
            },
            /*
             **卡使用记录
             **
             */
            {
                path: "/admin/carduse/:id",
                name: "卡使用记录",
                component: () => import("@/views/operations/card/carduse"),
            },
            /*
             **反馈管理
             **
             */
            {
                path: "/admin/response",
                name: "反馈管理",
                component: () => import("@/views/operations/response/response"),
            },
            /*
             **反馈详情
             **
             */
            {
                path: "/admin/response-single/:id",
                name: "反馈详情",
                component: () => import("@/views/operations/response/response-single"),
            },
            /*
             **我的
             **
             */
            {
                path: "/admin/mine",
                name: "我的",
                component: () => import("@/views/operations/mine/mine"),
            },
            /*
             **余额详情
             **
             */
            {
                path: "/admin/balance",
                name: "余额详情",
                component: () => import("@/views/operations/mine/balance"),
            },
            {
                path: "/admin/bind_wechat",
                name: "绑定微信",
                component: () => import("@/views/operations/mine/bind_wechat"),
            },
            /*
             **账号设置
             **
             */
            {
                path: "/admin/setting",
                name: "账号设置",
                component: () => import("@/views/operations/mine/setting"),
            },
            /*
             **提现记录
             **
             */
            {
                path: "/admin/drawcash",
                name: "提现记录",
                component: () => import("@/views/operations/mine/drawcash"),
            },
            /*
             **设备管理
             **
             */
            {
                path: "/admin/equip",
                name: "设备管理",
                component: () => import("@/views/operations/equip/equip"),
            },
            /*
             **设备编辑
             **
             */
            {
                path: "/admin/equipadd/:id",
                name: "设备编辑",
                component: () => import("@/views/operations/equip/equipadd"),
            },
            /*
             **设备添加
             **
             */
            {
                path: "/admin/equipadd",
                name: "设备添加",
                component: () => import("@/views/operations/equip/equipadd"),
            },
            {
                path: "/admin/port/:id",
                name: "设备端口",
                component: () => import("@/views/operations/equip/port"),
            },
            /*
             **用户管理
             **
             */
            {
                path: "/admin/users",
                name: "用户管理",
                component: () => import("@/views/operations/mine/users"),
            },
            /*
             **用户管理
             **
             */
            {
                path: "/admin/transferAccounts",
                name: "转账",
                component: () => import("@/views/operations/mine/transferAccounts"),
            },
            /*
             **规则管理
             **
             */
            {
                path: "/admin/rules",
                name: "规则管理",
                component: () => import("@/views/operations/rules/rules"),
            },
            {
                path: "/admin/rules-select",
                name: "选择规则",
                component: () => import("@/views/operations/rules/select"),
            },
            /*
             **规则编辑
             **
             */
            {
                path: "/admin/editrule/",
                name: "规则编辑",
                component: () => import("@/views/operations/rules/editrule"),
            },
            // {
            //   path: '/admin/editrule2/:id',
            //   name: 'Editrule',
            //   component: () => import('@/views/operations/rules/editrule2'),
            // },
            {
                path: "/admin/site",
                name: "站点管理",
                component: () => import("@/views/operations/site/index.vue"),
            },
            {
                path: "/admin/addSite",
                name: "站点新增/编辑",
                component: () => import("@/views/operations/site/addSite.vue"),
            },
            {
                path: "/admin/advert",
                name: "广告管理",
                component: () => import("@/views/operations/advert/index.vue"),
            },
            {
                path: "/admin/addAdvert",
                name: "广告新增/编辑",
                component: () => import("@/views/operations/advert/addAdvert.vue"),
            },
            {
                path: "/admin/promotion",
                name: "促销管理",
                component: () => import("@/views/operations/promotion/index.vue"),
            },
            {
                path: "/admin/addPromotion",
                name: "促销新增/编辑",
                component: () =>
                    import("@/views/operations/promotion/addPromotion.vue"),
            },
            {
                path: "/admin/tixian",
                name: "提现",
                component: () => import("@/views/operations/mine/tixian.vue"),
            },
            {
                path: "/admin/userInvest/:id",
                name: "用户充值记录",
                component: () => import("@/views/operations/mine/userInvest.vue"),
            },
            {
                path: "/admin/addCard",
                name: "卡新增/编辑",
                component: () => import("@/views/operations/card/addCard.vue"),
            },
            {
                path: "/admin/responseDetail",
                name: "问题详情",
                component: () =>
                    import("@/views/operations/response/responseDetail.vue"),
            },

            {
                path: "/admin/policy",
                name: "用户协议",
                component: () => import("@/views/operations/mine/policy.vue"),
            },
        ],
    },
];

const createRouter = () =>
    new Router({
        // mode: 'history', // require service support
        scrollBehavior: () => ({y: 0}),
        routes: constantRoutes,
    });

const router = createRouter();
// 从这到 -》》》》》》》》》
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});
// // 全局路由拦截-进入页面前执行
router.beforeEach((to, from, next) => {
    // 此处不可使用location.replace
    store.set("fullUrl", {name: to.fullPath}); // 保存用户进入的url
    store.set("latestUrl", {name: location.href});
    // 判断是否为微信登录
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        document.title = to.name;
        console.log(window.localStorage.getItem("token"));
        if (
            window.localStorage.getItem("token") != null ||
            to.path == "/login" ||
            to.path == "/admin/policy"
        ) {
            next();
        } else {
            next("/login");
        }
    } else {
        next("/401");
    }
});

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

// 《《《《《《-------从这打开
export default router;
