<template>
  <mu-card-header style="display:flex;align-items:Center;justify-content: space-between;margin-bottom: 15px;" >
     <div style="display:flex;align-items:Center">
        <mu-avatar slot="avatar" size="70" style="">
            <img :src="userinfo.userhead" />
        </mu-avatar>
        <div>
          <div style="color:#fff;font-size: 16px">{{userinfo.username}}</div>
          <div style="color:#fff;font-size: 13px;">{{userinfo.mobile}}</div>
        </div>
     </div>
     <span v-show="isShow" size="25" class="fright iconfont icon-xiala2"></span>
  </mu-card-header>
</template>
    <script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      userinfo:{
        userhead: this.$store.state.userinfo.headImage,
        username: this.$store.state.userinfo.userName,
        mobile: this.$store.state.userinfo.mobile,
      },
    };
  },
  mounted(){
    console.log(this.$store.state.userinfo)
  }
};
</script>