<template>
  <div class="search">
    <el-input
        :value="value"
        :placeholder="placeholder"
        @input="input"
        size="normal"
        clearable
    >
      <img slot="prefix" class="el-input__icon" src="@/assets/image/icon/search.png"/>
    </el-input>
  </div>
</template>
<script>
export default {
  name: "search-form",
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "请输入"
    },
  },
  methods: {
    input(val) {
      this.$emit('input', val)
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/state.scss";

.search {
  padding: 0.2864rem $divPadding;
  background-color: white;

  ::v-deep .el-input {
    background-color: #F5F5F5;
    padding: 0.0540rem 0;
    border-radius: 0.1621rem;

    .el-input__inner {
      background-color: transparent;
      font-size: $fontSize_12;
      padding: 0 0.5rem;
      line-height: normal;
      padding-left: 1rem !important;
    }

    .el-input__prefix {
      display: flex;
    }

    .el-input__icon {
      align-self: center;
      width: 0.4324rem;
      height: 0.4324rem;
      margin-left: 0.1rem;
    }
  }
}
</style>