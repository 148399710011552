/**
 * 接口域名的管理
 */
// 正式配置
// const base = {
//
//   //sq:'/api',//请求代理需要用到
//     sq:'http://wechat.nxptdn.com',
//     bd: 'http://operator.nxptdn.com',
//     client:'http://e.nxptdn.com',
//     appid:'wx9e5e67da26c51a14'
// }


//演示环境 
const base = {
  sq: '/api',//请求代理需要用到
  //bd: 'http://api.huizhidata.com',
 //  bd: 'http://192.168.6.104:9250',
 bd: 'http://47.113.107.174/api',
  client: 'http://wechat.szthlh.com',
  //慧知公众号
  appid: 'wx27cd5f621e3a888a'
}





export default base;


