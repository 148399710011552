<template>
  <div class="is-empty is-empty-component">
    <img class="empty-icon" src="@/assets/image/icon/empty.png" alt=""/>
    <slot name="desc"></slot>
  </div>
</template>
<script>
export default {
  name: 'is-empty',
  data() {
    return {}
  },
  props: {},
}
</script>
<style scoped lang="scss">
.is-empty {
  .empty-icon {
    width: 3.0810rem;
    height: 3.0810rem;
    margin: 0 calc((100% - 3.081rem) / 2);
    margin-top: 0.51rem;
    margin-bottom: 0.2rem;
  }
}
</style>