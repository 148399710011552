<template>
  <mu-card-header
    style="display: flex;align-items:center"
    :title="userinfo.username"
    :sub-title="userinfo.mobile"
  >
    <mu-avatar slot="avatar">
      <img :src="userinfo.userhead || defaultHead" />
    </mu-avatar>
    <div class="operate" v-if="operate">
      <div @click="options()">
        <img src="@/assets/image/option.png" alt="" />
      </div>
      <div @click="exits()">
        <img src="@/assets/image/exit.png" alt="" />
      </div>
    </div>
  </mu-card-header>
</template>
<script>
// import head from '@/assets/image/headImage.png'
import store from "store";
import { getInfoByKey } from "@/api/newApi";
export default {
  props: {
    operate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultHead: "",
      userinfo: {
        userhead: "",
        username: "",
        mobile: "",
      },
    };
  },
  methods: {
    options() {
      this.$emit("optionicon");
    },
    exits() {
      this.$emit("exiticon");
    },
    getConfigData(value) {
      let param = {
        configKey: value,
      };
      getInfoByKey(param).then((res) => {
        if (res.data) {
          this.defaultHead = res.data;
        }
      });
    },
  },
  created() {
    this.getConfigData("dls_login_logo");
  },
  mounted() {
    this.userinfo.username = store.get("adminuser").adminuser.realName;
    this.userinfo.userhead = store.get("adminuser").adminuser.headImage;
    this.userinfo.mobile = store.get("adminuser").adminuser.mobile;
  },
};
</script>
<style lang="scss" scoped>
.mu-card-header{
  padding: 0.2667rem 0;
  .mu-avatar{
    width: 1.28rem !important;
    height: 1.28rem !important;
    margin-right: 0.5333rem;
  }
}
.mu-card-title {
  font-size: 0.48rem;
  color: #000000;
}

.mu-card-sub-title {
  font-size: 0.32rem;
  color: #000000;
  opacity: 0.8;
}

.mu-card-header {
  position: relative;

  .operate {
    position: absolute;
    right: 0;

    img {
      width: 0.4389rem;
      height: 0.4389rem;
    }

    div {
      display: inline-block;
      margin-left: 0.4083rem;
    }
  }
}
</style>
