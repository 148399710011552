import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);


const state = {   //要设置的全局访问的state对象
  userinfo:'',//用户信息
  ChargingID: '',//充电桩ID
  ChargingCardID:'',
  adminuserinfo:'',
  setIphoneShareUrl:'',

  // userinfo:{
  //   headImage:"http://thirdwx.qlogo.cn/mmopen/ibIycB1NX4Uj7Myp7l6Uqrlb0B4rfX0Ex8CC7g1UsxX7a0iaqf8Qdt2ia2sgPhCbnABricL8zGACibz5H8ftpNqRrHrKsBwXJNl5ic/132",
  //   id: 1351,
  //   mobile: "15652258720",
  //   openId: "otHBiuCVbjd8msnELTJ02k6bMCgE",
  //   userName: "Sword",
  // }
};
const getters = {

};
const mutations = {
  //存入充电桩id
  setChargingID(state, id) {
    state.ChargingID = id;

  },
  setIphoneShareUrl(state, url) {
    state.setIphoneShareUrl = url;

  },
  setUser(state, obj) {
    state.userinfo = obj;

  },
  setChargingCardID(state, id) {
    state.ChargingCardID = id;

  },
  setAdminUser(state, obj) {
    state.adminuserinfo = obj;

  },

};
const actions = {
  setChargingID(context, id) {
    context.commit('setChargingID', id);
  },
  setIphoneShareUrl(context, url) {
    context.commit('setIphoneShareUrl', url);
  },
  setUser(context, obj) {
    context.commit('setUser', obj);

  },
  setChargingCardID(context, id) {
    context.commit('setChargingCardID', id);
  },
  setAdminUser(context, obj) {
    context.commit('setAdminUser', obj);

  },
};

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});

export default store;
